import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { connect } from 'react-redux'

import { validLicenseSettings } from './utils/api.js'
import useToken from './utils/useToken.js'
import router from './routes/app.js'
import { Enums, Categories, Settings } from './utils/settings.js'
import { getEnums, getCategories, getProfile } from './api/coll.js'
import { Spin } from 'antd'
import RouterGuard from '@/components/RouterGuard/index.jsx'

validLicenseSettings()

function App(props) {
  const { getToken } = useToken()
  try {
    getEnums().then((res) => {
      for (let [key, value] of Object.entries(res)) {
        Enums[key] = value
      }
      Enums.entry_statuses = [
        { value: -100, label: '已作废' },
        { value: -1, label: '待提交' },
        { value: 0, label: '待评审' },
        { value: 1, label: '已拒绝' },
        { value: 2, label: '已退回' },
        { value: 3, label: '初评通过' }
      ]
    })
    getCategories({ filter: { type: 'events' }}).then(res => {
      Categories.events = res.list
    })
  } catch (e) {}

  if (getToken()) {
    getProfile().then((res) => {
      Settings.profile = res
    })
  }

  return (
    <Spin
      size="large"
      style={{
        backgroundColor:'rgba(0,0,0,0.75)',
        minHeight:'100vh',
        position:'fixed'
      }}
      spinning={props.isLoading}
    >
      <React.Suspense fallback={<div>Loading...</div>}>
        <RouterGuard>
          <RouterProvider router={router} />
        </RouterGuard>
      </React.Suspense>
    </Spin>
  )
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.LoadingReducer.isLoading
  }
}

export default connect(mapStateToProps)(App)
