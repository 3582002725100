export default function RouterGuard(props) {
  const contest1 = '648d35b8-0a00-0802-37db-e2d284846ede' // 好创意  
  const contest2 = '6539c92b-0a00-0802-eec2-d37fe1acb93e' // 教师技能大赛
  switch(window.location.hostname){
    case 'localhost':
      localStorage.setItem('coll_eventid', contest1)
      break;
    case '192.168.31.183':
      localStorage.setItem('coll_eventid', contest2)
      break;

    case 'coll-cdec.huahanwenhua.com':
      localStorage.setItem('coll_eventid', contest1)
      break;
    case 'tcoll-cdec.huahanwenhua.com':
      localStorage.setItem('coll_eventid', contest2)
      break;

    case 'coll.cdec.org.cn':
      localStorage.setItem('coll_eventid', contest1)
      break;
    case 'tcoll.cdec.org.cn':
      localStorage.setItem('coll_eventid', contest2)
      break;

    default:
      localStorage.setItem('coll_eventid', contest1)
      break
  }
  return props.children
}