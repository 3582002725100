import React from 'react'

import styles from './index.module.css'

export default function Loader() {
  return (
    <div className="h-full w-full flex-1 flex justify-center items-center">
    <div className={styles.loader}>
        <span className={styles.hour}></span>
        <span className={styles.min}></span>
        <span className={styles.circle}></span>
    </div>
    </div>
  )
}
