import Api from '../utils/api';
import { calculateAge } from '../utils/utility';

import logo from '../assets/images/logo.png'

export const dataPersonFormatter = (person) => {
    return {
        ...person,
        key: person.id,
        fullname: person.fullname ? person.fullname : person.lastname + person.firstname,
        avatarurl: person.avatarurl !== '' ? person.avatarurl : logo,
        logourl: person.logourl !== '' ? person.logourl : logo,
        age: calculateAge(person.birthdate),
        birthdate: new Date(person.birthdate * 1000).toLocaleDateString(),
    }
}

export const getProfile = async () => {
    return await Api.get(`/me/profile`).then((res) => {
        return dataPersonFormatter(res);
    })
}

export const getEntries = async (params) => {
    return await Api.get(`/me/entries`, params).then((res) => {
        return res;
    })
}

export const getEntry = async (entryid) => {
    return await Api.get(`/me/entries/${entryid}`).then((res) => {
        return res;
    })
}

export const getEntriesStats = async () => {
    return await Api.get(`/me/entries/stats`).then((res) => {
        return res;
    })
}

export const returnEntry = async (entryid, params) => {
    return await Api.put(`/me/entries/${entryid}/return`, params).then((res) => {
        return res;
    })
}

export const rejectEntry = async (entryid) => {
    return await Api.put(`/me/entries/${entryid}/reject`).then((res) => {
        return res;
    })
}

// export const finalistEntry = async (entryid) => {
//     return await Api.put(`/me/entries/${entryid}/finalist`).then((res) => {
//         return res;
//     })
// }

export const postFinalist = async (entryid) => {
    return await Api.post(`/me/finalists`, { entryid }).then((res) => {
        return res;
    })
}

export const getFinalists = async (params) => {
    return await Api.get(`/me/finalists`, params).then((res) => {
        return res;
    })
}

// export const postAward = async (entryid) => {
//     return await Api.post(`/me/awards`, { entryid }).then((res) => {
//         return res;
//     })
// }

export const postAward = async (finalistid) => {
    return await Api.post(`/me/awards`, { finalistid }).then((res) => {
        return res;
    })
}

export const getAdwards = async (params) => {
    return await Api.get(`/me/awards`, params).then((res) => {
        return res;
    })
}

export const putAdvance = async (awardid) => {
    return await Api.put(`/me/awards/${awardid}/advance`).then((res) => {
        return res;
    })
}

export const getCategories = async (params = {}) => {
    return await Api.get(`/categories`, params).then((res) => {
        return res;
    })
}

export const getEnums = async () => {
    return await Api.get(`/settings/enums`).then((res) => {
        return res;
    })
}

export const signin = async (params) => {
    return await Api.get('/auth/signin', params).then((res) => {
        return res;
    })
}

export const getEvents = async (params = {}) => {
    return await Api.get('/events', params).then((res) => {
        return res;
    })
}

export const getrMembers = async (params) => {
    return await Api.get(`/me/members`, params).then((res) => {
        return res;
    })
}

export const postMembers = async (data) => {
    return await Api.post(`/me/members`, data).then((res) => {
        return res;
    })
}

// 修改评委-分类
export const postMembersCateApi = async (collaboratorid, data) => {
    return await Api.put(`/collaborators/${collaboratorid}/member`, data).then((res) => {
        return res;
    })
}

export const deleteMember = async (memberid) => {
    return await Api.delete(`/me/members/${memberid}`).then((res) => {
        return res;
    })
}

// 批量初评
export const batchFinalistsApi = async (entryids) => {
    return await Api.post(`/me/finalists/batchAudit`, { entryids }).then((res) => {
        return res;
    })
}

// 批量复评
export const batchAwardsApi = async (finalistids) => {
    return await Api.post(`/me/awards/batchAudit`, { finalistids }).then((res) => {
        return res;
    })
}

// 批量晋级国赛
export const batchAdvanceApi = async (awardids) => {
    return await Api.put(`/me/awards/batchAdvance`, { awardids }).then((res) => {
        return res;
    })
}

// 复评撤回
export const awardsBackApi = async (awardid) => {
    return await Api.post(`/me/awards/${awardid}/back`).then((res) => {
        return res;
    })
}